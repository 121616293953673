import type { CallToActionModel } from '~/models'

export const useCtaLink = (cta: CallToActionModel) => {
  const email = ref(cta.elements.emailAddress.value)
  const genericAsset = ref(cta.elements.genericAssetTarget.value)
  const linkedTarget = ref(cta.elements.linkedTarget.value)
  const manualTarget = ref(cta.elements.manualTarget.value)
  if (linkedTarget.value.length > 0) {
    const codename = linkedTarget.value[0]
    const siteStore = useSiteStore()
    return siteStore.getUrlByCodename(codename)
  } else if (genericAsset.value.length > 0) {
    return genericAsset.value[0].url
  } else if (manualTarget.value.length > 0) {
    return manualTarget.value
  } else if (email.value.length > 0) {
    return `mailto:${email.value}`
  }
  return ''
}
