<template>
    <span v-if="useRuntimeConfig().public.enableSmartLinks" :data-kontent-element-codename="codename"
        :data-kontent-item-id="itemId">
        <slot></slot>
    </span>
    <template v-else>
        <slot></slot>
    </template>
</template>
<script setup lang="ts">
const props = defineProps<{ codename: string, itemId: string }>()
const itemId = computed(() => props.itemId?.replace('_', ''))
</script>