<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 13268">
            <circle id="Ellipse 117" cx="14" cy="14" r="13.25" fill="#15B5DA" stroke="#15B5DA" stroke-width="1.5" />
            <g id="Group 13267">
                <path id="Vector 31" d="M7.36377 18.8182V21.0001H20.6365V18.8182" stroke="white" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                <path id="Arrow 3"
                    d="M14.7502 5C14.7502 4.58579 14.4145 4.25 14.0002 4.25C13.586 4.25 13.2502 4.58579 13.2502 5L14.7502 5ZM13.4699 17.5303C13.7628 17.8232 14.2377 17.8232 14.5306 17.5303L19.3035 12.7574C19.5964 12.4645 19.5964 11.9896 19.3035 11.6967C19.0107 11.4038 18.5358 11.4038 18.2429 11.6967L14.0002 15.9393L9.7576 11.6967C9.46471 11.4038 8.98984 11.4038 8.69694 11.6967C8.40405 11.9896 8.40405 12.4645 8.69694 12.7574L13.4699 17.5303ZM13.2502 5L13.2502 17L14.7502 17L14.7502 5L13.2502 5Z"
                    fill="white" />
            </g>
        </g>
    </svg>
</template>