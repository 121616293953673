/**
 * Generated by '@kontent-ai/model-generator@6.5.1'
 */
export const contentTypes = {
    /**
     *    Footer
     */
    footer: {
        codename: 'footer',
        id: '039f9249-48a1-481e-a3e8-708f4c8862ee',
        externalId: undefined,
        name: '🌍 Footer',
        elements: {
            /**
             * Logo (asset)
             */
            logo: {
                codename: 'logo',
                id: 'eb04f35e-7ea6-4531-9274-966cb97b13f1',
                externalId: undefined,
                name: 'Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Navigation Links (modular_content)
             */
            navigation_links: {
                codename: 'navigation_links',
                id: 'be63d20a-1bfb-4708-8f7a-9a4302dd2d59',
                externalId: undefined,
                name: 'Navigation Links',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     *    Header
     */
    header: {
        codename: 'header',
        id: '6e34d4e9-ab00-49cd-8110-b3bceeeca487',
        externalId: undefined,
        name: '🌍 Header',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '9926cd74-d544-4766-b118-93de97285b00',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Legal Text (text)
             */
            legal_text: {
                codename: 'legal_text',
                id: '51d6502d-7118-4996-9a60-f869dec5b6f7',
                externalId: undefined,
                name: 'Legal Text',
                required: false,
                type: 'text'
            },

            /**
             * Logo (asset)
             */
            logo: {
                codename: 'logo',
                id: '2fbb02fd-3136-4459-8d5a-ce010cc3d04c',
                externalId: undefined,
                name: 'Logo',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Navigation Link
     */
    _navigation_link: {
        codename: '_navigation_link',
        id: '081cb6ea-c3b5-4aed-86d7-5e218992bdf6',
        externalId: undefined,
        name: '🌍 Navigation Link',
        elements: {
            /**
             * Footer Links (modular_content)
             */
            footer_links: {
                codename: 'footer_links',
                id: 'a688b327-f78e-4a56-a369-8ebd8083ba98',
                externalId: undefined,
                name: 'Footer Links',
                required: false,
                type: 'modular_content'
            },

            /**
             * Link (text)
             */
            link: {
                codename: 'link',
                id: 'a4d97dcd-10d0-4a83-a708-229e9425bb24',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '2068fe24-4584-4c7a-957c-0433797e47fc',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Script Tag
     */
    _script_tag: {
        codename: '_script_tag',
        id: 'da70875b-97cb-450b-a145-75164716f823',
        externalId: undefined,
        name: '🌍 Script Tag',
        elements: {
            /**
             * Inner Code (text)
             */
            inner_code: {
                codename: 'inner_code',
                id: '6567b242-45b7-4ad6-8488-b41bf9e04dae',
                externalId: undefined,
                name: 'Inner Code',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Site Configuration
     */
    _site_configuration: {
        codename: '_site_configuration',
        id: '3c211270-0071-4e93-94f3-90b26a82f46e',
        externalId: undefined,
        name: '🌍 Site Configuration',
        elements: {
            /**
             * Favicon (asset)
             */
            favicon: {
                codename: 'favicon',
                id: 'fc27fdfb-fbef-4798-bed0-4c01182da77a',
                externalId: undefined,
                name: 'Favicon',
                required: false,
                type: 'asset'
            },

            /**
             * Footer (modular_content)
             */
            footer: {
                codename: 'footer',
                id: 'f780a35f-8f8d-4655-917a-475f4c0b5615',
                externalId: undefined,
                name: 'Footer',
                required: false,
                type: 'modular_content'
            },

            /**
             * Header (modular_content)
             */
            header: {
                codename: 'header',
                id: '56c84bf6-aa51-487e-81a5-244fb86ffb96',
                externalId: undefined,
                name: 'Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * Script Tags (modular_content)
             */
            script_tags: {
                codename: 'script_tags',
                id: '40226ef3-ef6e-44ae-b3b7-019f7794e01f',
                externalId: undefined,
                name: 'Script Tags',
                required: false,
                type: 'modular_content'
            },

            /**
             * Site Name (text)
             */
            site_name: {
                codename: 'site_name',
                id: '96e7cf9b-27f1-4d0c-b447-2128777a8a3f',
                externalId: undefined,
                name: 'Site Name',
                required: false,
                type: 'text'
            },

            /**
             * TrustArc Code Block (text)
             */
            trustarc_code_block: {
                codename: 'trustarc_code_block',
                id: '10235c41-1518-4555-9a56-6e964ce240b5',
                externalId: undefined,
                name: 'TrustArc Code Block',
                required: false,
                type: 'text'
            },

            /**
             * Website Logo (asset)
             */
            website_logo: {
                codename: 'website_logo',
                id: '5ddb4ac0-0a74-4633-bbee-14343bd816ab',
                externalId: undefined,
                name: 'Website Logo',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Homepage   Root
     */
    web_spotlight_root: {
        codename: 'web_spotlight_root',
        id: 'b06bd633-7a13-4d7b-b41c-8bed6488cdf1',
        externalId: undefined,
        name: '📄 Homepage - Root',
        elements: {
            /**
             * Banner Text (rich_text)
             */
            banner_text: {
                codename: 'banner_text',
                id: 'e9bcb956-5443-401e-be6c-289cbf8e4be7',
                externalId: undefined,
                name: 'Banner Text',
                required: false,
                type: 'rich_text'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '07fdd58a-86e4-44e5-9fa0-5760c952288d',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: 'ca96e196-4add-4657-b3d2-24446c4f8833',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Description (text)
             */
            seo_metadata__meta_description: {
                codename: 'seo_metadata__meta_description',
                id: '979419d7-4d99-43e2-9900-6b6f12fc433a',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: 'e4044407-0eaa-46d2-9e30-3a29201c3548',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '033a1b56-57e1-4443-9dae-98439c632065',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'dc0a4b02-590f-4e3d-90ad-48a2a990672f',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'aed87632-72b5-4ba3-bb17-fdf005ebe106',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Interior Page
     */
    interior_page: {
        codename: 'interior_page',
        id: 'd2997a91-9f3d-46cc-a553-2efe54e3f614',
        externalId: undefined,
        name: '📄 Interior Page',
        elements: {
            /**
             * Content (rich_text)
             */
            content_: {
                codename: 'content_',
                id: '68ad09df-4a77-428e-99be-351286284bcf',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: '9361c8d0-84ed-4bfc-9e27-a689aa5da9c0',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Description (text)
             */
            seo_metadata__meta_description: {
                codename: 'seo_metadata__meta_description',
                id: '979419d7-4d99-43e2-9900-6b6f12fc433a',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: 'e4044407-0eaa-46d2-9e30-3a29201c3548',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '3c32b346-9069-4615-b549-d160946d90e9',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a0615d96-1f19-4fea-8729-0ef6ae7c3172',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '4e3e1f94-c10e-4a59-b7b6-99f1e7c4866a',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Anchor Tag
     */
    anchor_tag: {
        codename: 'anchor_tag',
        id: 'e8ce37d4-a751-4d41-b4ef-7ea17ac2fa51',
        externalId: undefined,
        name: '🔠 Anchor Tag',
        elements: {
            /**
             * ID (text)
             */
            id: {
                codename: 'id',
                id: '65338d48-33ac-471d-9731-f8fc81c97b9a',
                externalId: undefined,
                name: 'ID',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Call to Action
     */
    call_to_action: {
        codename: 'call_to_action',
        id: '0d0e068b-800d-47ed-9896-4900604b4890',
        externalId: undefined,
        name: '🔠 Call to Action',
        elements: {
            /**
             * Email Address (text)
             */
            email_address: {
                codename: 'email_address',
                id: '1a0ff626-607a-42a8-9660-89aa61adf53a',
                externalId: undefined,
                name: 'Email Address',
                required: false,
                type: 'text'
            },

            /**
             * Generic Asset Target (asset)
             */
            generic_asset_target: {
                codename: 'generic_asset_target',
                id: '7f4502e0-e932-4f82-a15a-233424f225e2',
                externalId: undefined,
                name: 'Generic Asset Target',
                required: false,
                type: 'asset'
            },

            /**
             * Link Title (text)
             */
            link_title: {
                codename: 'link_title',
                id: '375cfa47-4408-4b37-bbd4-ac7a0b5bae74',
                externalId: undefined,
                name: 'Link Title',
                required: false,
                type: 'text'
            },

            /**
             * Linked Target (modular_content)
             */
            linked_target: {
                codename: 'linked_target',
                id: '19f89167-4368-4f9b-ac9e-f67730e2bb24',
                externalId: undefined,
                name: 'Linked Target',
                required: false,
                type: 'modular_content'
            },

            /**
             * Manual Target (text)
             */
            manual_target: {
                codename: 'manual_target',
                id: '139e2912-4b9e-4619-9a89-5b61efa1edd2',
                externalId: undefined,
                name: 'Manual Target',
                required: false,
                type: 'text'
            },

            /**
             * Phone Number (rich_text)
             */
            phone_number: {
                codename: 'phone_number',
                id: 'ce19ae0f-4841-408d-85b6-17bc831e48da',
                externalId: undefined,
                name: 'Phone Number',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Card Item
     */
    card_item: {
        codename: 'card_item',
        id: '1fcdddc4-a8ea-4d21-aa20-e3436a3011b8',
        externalId: undefined,
        name: '🔠 Card Item',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '51c5eb96-7a69-4ed6-aa0d-3b75414efb91',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: 'e1377a37-788e-4bc4-a903-d172f2c66611',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '2fcab0e9-ca35-4f91-8226-7049152b4e2e',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '1874c171-7961-4c76-abf9-2285bbbdf4bd',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Publication Item
     */
    _publication_item: {
        codename: '_publication_item',
        id: '18defeb3-dafb-418a-9f17-9cc111d49a7d',
        externalId: undefined,
        name: '🔠 Publication Item',
        elements: {
            /**
             * Author Information (text)
             */
            author_information: {
                codename: 'author_information',
                id: 'c7444741-6389-43b2-a01f-4410a72911d5',
                externalId: undefined,
                name: 'Author Information',
                required: false,
                type: 'text'
            },

            /**
             * Link to Publication (text)
             */
            link_to_publication: {
                codename: 'link_to_publication',
                id: 'a4f6adae-4c18-4d90-9a9f-e0d250080f11',
                externalId: undefined,
                name: 'Link to Publication',
                required: false,
                type: 'text'
            },

            /**
             * Publication Date (date_time)
             */
            publication_date: {
                codename: 'publication_date',
                id: '082ac996-9fa2-4aa8-90e2-c580fd00feb5',
                externalId: undefined,
                name: 'Publication Date',
                required: false,
                type: 'date_time'
            },

            /**
             * Publication Title (text)
             */
            publication_title: {
                codename: 'publication_title',
                id: '999c2689-8d86-44a3-adf7-cd76c094d8cf',
                externalId: undefined,
                name: 'Publication Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Split Content Block   Basic Content
     */
    split_content_block_basic_content: {
        codename: 'split_content_block_basic_content',
        id: 'f2921a37-e20b-4676-b90c-3d26478fa928',
        externalId: undefined,
        name: '🔠 Split Content Block - Basic Content',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: 'd0648ee3-fbc6-4e91-8fec-09cf1dd1fd36',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '1c979f69-78f5-48ca-9ab1-4aba9e2d74ca',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Split Content Block   Image
     */
    split_content_block_image: {
        codename: 'split_content_block_image',
        id: '3093df31-322f-413d-a337-64f4ee4f93a4',
        externalId: undefined,
        name: '🔠 Split Content Block - Image',
        elements: {
            /**
             * Caption (text)
             */
            caption: {
                codename: 'caption',
                id: 'c061d34f-bd00-4f5d-af01-b4e5c153ac48',
                externalId: undefined,
                name: 'Caption',
                required: false,
                type: 'text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'bdfedb0b-a585-489d-a65c-34ef51dd4fe4',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Split Content Block   Publication List
     */
    split_content_block_publication_list: {
        codename: 'split_content_block_publication_list',
        id: '2485c1cd-a6cc-42da-81a5-5ca1dcb183eb',
        externalId: undefined,
        name: '🔠 Split Content Block - Publication List',
        elements: {
            /**
             * Mobile Call to Action (modular_content)
             */
            mobile_call_to_action: {
                codename: 'mobile_call_to_action',
                id: 'ab151e27-75ab-4eab-91d8-1b8febd49779',
                externalId: undefined,
                name: 'Mobile Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Number of items to display (number)
             */
            number_of_items_to_display: {
                codename: 'number_of_items_to_display',
                id: '3db1c46d-f122-47af-a3eb-4ccead26b681',
                externalId: undefined,
                name: 'Number of items to display',
                required: false,
                type: 'number'
            }
        }
    },

    /**
     *    Banner
     */
    banner: {
        codename: 'banner',
        id: 'fd65cd22-3484-4ec5-b672-65a21977cbe5',
        externalId: undefined,
        name: '🧩 Banner',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '9536f864-720b-4c15-a8e0-93ac5f973a6a',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: 'e4eca57f-305c-48f4-97f2-a1dc77b0b769',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Design (multiple_choice)
             */
            design: {
                codename: 'design',
                id: '3cb61432-dd70-4e49-bf95-84071f5b750e',
                externalId: undefined,
                name: 'Design',
                required: false,
                type: 'multiple_choice',
                options: {
                    light: {
                        name: 'Light',
                        id: '97302b5a-75c6-4c5b-8b3b-9cf8728664dd',
                        codename: 'light',
                        externalId: undefined
                    },
                    dark: {
                        name: 'Dark',
                        id: '68e63758-6af0-4ffa-8fd3-79194b6178f8',
                        codename: 'dark',
                        externalId: undefined
                    }
                }
            },

            /**
             * Eyebrow (text)
             */
            eyebrow: {
                codename: 'eyebrow',
                id: '87244be8-5508-4f21-82f2-2ec02d1f876c',
                externalId: undefined,
                name: 'Eyebrow',
                required: false,
                type: 'text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'cfcc31e7-bc03-498b-945f-ba50523424ac',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Image Placement (multiple_choice)
             */
            image_placement: {
                codename: 'image_placement',
                id: '1f60e3cd-d630-47c8-8f77-6af368b59209',
                externalId: undefined,
                name: 'Image Placement',
                required: false,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: 'acdb35c5-1d66-459d-91fe-572683fff7f1',
                        codename: 'left',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: 'aa8d4d87-58ff-43ab-a228-c1488fd46223',
                        codename: 'right',
                        externalId: undefined
                    }
                }
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: '353ddb62-f460-4796-a556-976fa5f73cec',
                externalId: undefined,
                name: 'Layout',
                required: false,
                type: 'multiple_choice',
                options: {
                    container: {
                        name: 'Container',
                        id: 'ca8986b4-a437-4f8e-b0dd-76ed8294f233',
                        codename: 'container',
                        externalId: undefined
                    },
                    full_width: {
                        name: 'Full Width',
                        id: '1ea3e23a-1b72-44d7-8ba5-b984221eb4dc',
                        codename: 'full_width',
                        externalId: undefined
                    }
                }
            },

            /**
             * Menu Title (text)
             */
            anchor_tag_navigation__menu_title: {
                codename: 'anchor_tag_navigation__menu_title',
                id: '7acebf12-a5c1-408d-87b0-f6e340dfe770',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Show in Navigation (multiple_choice)
             */
            anchor_tag_navigation__show_in_navigation: {
                codename: 'anchor_tag_navigation__show_in_navigation',
                id: '808b1815-0843-4950-b5b6-e55b2338911d',
                externalId: undefined,
                name: 'Show in Navigation',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'e3eca8d1-3a2c-41eb-b136-858e31276701',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '2f8aca11-239c-46a9-a3fd-eb5c1082b0a2',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'ad850354-6b45-4483-aa88-c1cea1d0a3e1',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Basic Content Section
     */
    basic_content_section: {
        codename: 'basic_content_section',
        id: 'fd95dc0f-42e0-4842-955b-4b9f49b3ce29',
        externalId: undefined,
        name: '🧩 Basic Content Section',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '5f775e3e-0e25-4aa6-95e5-283d4a01e84c',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: '8f74ab14-7755-4a8c-8dcb-0e03b63c06a3',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: '26ef4eb8-0c63-4d17-bb96-d60d7e7a6b53',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '23e8ccc3-803b-452c-90ea-08aee4aa036f',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            }
        }
    },

    /**
     *    Card Collection
     */
    card_collection: {
        codename: 'card_collection',
        id: '88b52832-57d2-4c4f-8081-53fc3f7ffe14',
        externalId: undefined,
        name: '🧩 Card Collection',
        elements: {
            /**
             * Cards (modular_content)
             */
            cards: {
                codename: 'cards',
                id: '6436bf39-2846-40c8-b83e-e317021bc189',
                externalId: undefined,
                name: 'Cards',
                required: false,
                type: 'modular_content'
            },

            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: '87a5b0d6-7c43-42cd-bc41-70379a48d9e4',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Menu Title (text)
             */
            anchor_tag_navigation__menu_title: {
                codename: 'anchor_tag_navigation__menu_title',
                id: '7acebf12-a5c1-408d-87b0-f6e340dfe770',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Show in Navigation (multiple_choice)
             */
            anchor_tag_navigation__show_in_navigation: {
                codename: 'anchor_tag_navigation__show_in_navigation',
                id: '808b1815-0843-4950-b5b6-e55b2338911d',
                externalId: undefined,
                name: 'Show in Navigation',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'e3eca8d1-3a2c-41eb-b136-858e31276701',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '2f8aca11-239c-46a9-a3fd-eb5c1082b0a2',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '370f4d02-ac19-485d-b18c-6ed34c339e1b',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Contact Block
     */
    contact_block: {
        codename: 'contact_block',
        id: '297a8986-9f93-4e2e-9d59-63572913f412',
        externalId: undefined,
        name: '🧩 Contact Block',
        elements: {
            /**
             * Description (rich_text)
             */
            description: {
                codename: 'description',
                id: 'b69547b4-6742-4eae-920a-8d093e960c56',
                externalId: undefined,
                name: 'Description',
                required: false,
                type: 'rich_text'
            },

            /**
             * Menu Title (text)
             */
            anchor_tag_navigation__menu_title: {
                codename: 'anchor_tag_navigation__menu_title',
                id: '7acebf12-a5c1-408d-87b0-f6e340dfe770',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Show in Navigation (multiple_choice)
             */
            anchor_tag_navigation__show_in_navigation: {
                codename: 'anchor_tag_navigation__show_in_navigation',
                id: '808b1815-0843-4950-b5b6-e55b2338911d',
                externalId: undefined,
                name: 'Show in Navigation',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'e3eca8d1-3a2c-41eb-b136-858e31276701',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '2f8aca11-239c-46a9-a3fd-eb5c1082b0a2',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'e11b9b55-d35e-4015-8d9e-679d17169659',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Home Hero
     */
    home_hero: {
        codename: 'home_hero',
        id: '43560bd6-5d51-42b5-b5ee-db2773634240',
        externalId: undefined,
        name: '🧩 Home Hero',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: 'cfbd240d-4e39-4cac-811c-643faec8ffe9',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Eyebrow (text)
             */
            eyebrow: {
                codename: 'eyebrow',
                id: '1d0b3734-bf1c-4bc6-8718-bdb6be65784b',
                externalId: undefined,
                name: 'Eyebrow',
                required: false,
                type: 'text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '533c44c8-f6cc-4515-884c-0ccca0ef713f',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'fbf1cdd9-7981-40dc-aaba-9e55a65a7296',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Image Block
     */
    image_block: {
        codename: 'image_block',
        id: 'dddb28fe-3721-48ab-b025-e1a1216a470b',
        externalId: undefined,
        name: '🧩 Image Block',
        elements: {
            /**
             * Call to Action (modular_content)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '4039e8c2-d6a0-4bc5-a789-6a5a20d3d69c',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'modular_content'
            },

            /**
             * Caption (text)
             */
            caption: {
                codename: 'caption',
                id: 'bccdbe5f-56a9-46ce-b7b1-77a3e111dcee',
                externalId: undefined,
                name: 'Caption',
                required: false,
                type: 'text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '1b64d266-f95d-4e2c-b70c-6de2872d9e27',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Menu Title (text)
             */
            anchor_tag_navigation__menu_title: {
                codename: 'anchor_tag_navigation__menu_title',
                id: '7acebf12-a5c1-408d-87b0-f6e340dfe770',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Show in Navigation (multiple_choice)
             */
            anchor_tag_navigation__show_in_navigation: {
                codename: 'anchor_tag_navigation__show_in_navigation',
                id: '808b1815-0843-4950-b5b6-e55b2338911d',
                externalId: undefined,
                name: 'Show in Navigation',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'e3eca8d1-3a2c-41eb-b136-858e31276701',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '2f8aca11-239c-46a9-a3fd-eb5c1082b0a2',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'b1398ed2-7ccb-4283-abda-dcad356968b3',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Interior Hero
     */
    interior_hero: {
        codename: 'interior_hero',
        id: 'cdcd40ba-ab63-4baa-a3fe-0b956947bdf4',
        externalId: undefined,
        name: '🧩 Interior Hero',
        elements: {
            /**
             * Eyebrow (text)
             */
            eyebrow: {
                codename: 'eyebrow',
                id: '02a03376-e469-42e8-a1d4-fc81fee7386a',
                externalId: undefined,
                name: 'Eyebrow',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '91efee3c-71f8-42cc-8537-c3d3546653ad',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Publication List
     */
    publication_list: {
        codename: 'publication_list',
        id: '32d650c2-4b06-405c-b499-c70e2e06e2a3',
        externalId: undefined,
        name: '🧩 Publication List',
        elements: {
            /**
             * Number of items to display (number)
             */
            number_of_items_to_display: {
                codename: 'number_of_items_to_display',
                id: '00dd0da1-8639-47c2-9b9e-cfcc56c03933',
                externalId: undefined,
                name: 'Number of items to display',
                required: false,
                type: 'number'
            }
        }
    },

    /**
     *    Split Content Block
     */
    split_content_block: {
        codename: 'split_content_block',
        id: 'b9129657-6899-452c-9022-e22f32c6953a',
        externalId: undefined,
        name: '🧩 Split Content Block',
        elements: {
            /**
             * Background (multiple_choice)
             */
            background: {
                codename: 'background',
                id: 'ecb6a29b-ec55-433d-9d34-510841ef0ed9',
                externalId: undefined,
                name: 'Background',
                required: false,
                type: 'multiple_choice',
                options: {
                    light: {
                        name: 'Light',
                        id: 'c4e2412e-53b2-4f5a-bf9a-ea92df697922',
                        codename: 'light',
                        externalId: undefined
                    },
                    dark: {
                        name: 'Dark',
                        id: '014a0449-d664-4875-8ca0-698b447f65d3',
                        codename: 'dark',
                        externalId: undefined
                    }
                }
            },

            /**
             * Layout (multiple_choice)
             */
            layout: {
                codename: 'layout',
                id: 'bd74a023-f04c-45ba-93fd-7b17353b4391',
                externalId: undefined,
                name: 'Layout',
                required: false,
                type: 'multiple_choice',
                options: {
                    n30_70: {
                        name: '30 70',
                        id: '9093ed43-97b9-4c19-9cbb-651c258fad91',
                        codename: 'n30_70',
                        externalId: undefined
                    },
                    n70_30: {
                        name: '70 30',
                        id: 'b7839a1e-6a5e-4ea5-9785-457a7456f388',
                        codename: 'n70_30',
                        externalId: undefined
                    }
                }
            },

            /**
             * Left Content (rich_text)
             */
            left_content: {
                codename: 'left_content',
                id: '19ce6239-0e19-4cfb-8abe-269d1c63f9d3',
                externalId: undefined,
                name: 'Left Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Menu Title (text)
             */
            anchor_tag_navigation__menu_title: {
                codename: 'anchor_tag_navigation__menu_title',
                id: '7acebf12-a5c1-408d-87b0-f6e340dfe770',
                externalId: undefined,
                name: 'Menu Title',
                required: false,
                type: 'text',

                snippetCodename: 'anchor_tag_navigation'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: '8f74ab14-7755-4a8c-8dcb-0e03b63c06a3',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: '26ef4eb8-0c63-4d17-bb96-d60d7e7a6b53',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '23e8ccc3-803b-452c-90ea-08aee4aa036f',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Right Content (rich_text)
             */
            right_content: {
                codename: 'right_content',
                id: '21a5ab9e-24ec-4b17-acbf-1f0c386cd5a4',
                externalId: undefined,
                name: 'Right Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Show in Navigation (multiple_choice)
             */
            anchor_tag_navigation__show_in_navigation: {
                codename: 'anchor_tag_navigation__show_in_navigation',
                id: '808b1815-0843-4950-b5b6-e55b2338911d',
                externalId: undefined,
                name: 'Show in Navigation',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'e3eca8d1-3a2c-41eb-b136-858e31276701',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: '2f8aca11-239c-46a9-a3fd-eb5c1082b0a2',
                        codename: 'no',
                        externalId: undefined
                    }
                },
                snippetCodename: 'anchor_tag_navigation'
            }
        }
    }
} as const;
