import { transformImageUrl } from '@kontent-ai/delivery-sdk'
import type { ProviderGetImage } from '@nuxt/image'

export const getImage: ProviderGetImage = (src, { modifiers = {}, baseURL } = {}) => {
  const transformedUrl = transformImageUrl(src).withAutomaticFormat().getUrl()

  return {
    url: transformedUrl
  }
}
